// const baseurl = "http://172.16.2.13:3000/";
// const baseurl = "http://172.16.1.39:3000/";
const baseurl = "https://nodejoshua-nftmarketplace.mobiloitte.io/";

let user = `${baseurl}api/v1/user`;
let nft = `${baseurl}api/v1/nft`;
let order = `${baseurl}api/v1/order`;
let category = `${baseurl}api/v1/category`;
let collection = `${baseurl}api/v1/collection`;
let album = `${baseurl}api/v1/album`;
let offer = `${baseurl}api/v1/offer`;
let staticContent = `${baseurl}api/v1/static`;
let adminData = `${baseurl}api/v1/admin`;
let categoryData = `${baseurl}api/v1/category`;

const Apiconfigs = {
  //-----*USER-API*-----//
  connectWallet: `${user}/connectWallet`,
  getProfile: `${user}/getProfile`,
  uploadFile: `${user}/uploadFile`,
  updateProfile: `${user}/updateProfile`,
  likeDislikeNFT: `${user}/likeDislikeNFT`,
  userFollowingCount: `${user}/userFollowingCount`,
  userFollowerCount: `${user}/userFollowerCount`,
  activityList: `${user}/activityList`,

  userNftRecordCount: `${user}/userNftRecordCount`,
  listActivityFilter: `${user}/listActivityFilter`,
  creatorForm: `${user}/creatorForm`,
  priceHistoryGraph: `${user}/priceHistoryGraph`,

  //-----*NFT-API*-----//
  uploadImage: `${nft}/uploadImage`,
  ipfsUpload: `${nft}/ipfsUpload`,
  createNFT: `${nft}/createNFT`,
  uploadNFT: `${nft}/uploadNFT`,
  nftMenu: `${nft}/nftMenu`,
  mylistNft: `${nft}/mylistNft`,
  mySoldNft: `${nft}/mySoldNft`,

  //-----*CATEGORY-API*-----//
  categoryList: `${category}/categoryList`,
  moodList: `${category}/moodList`,

  //-----*ORDER-API*-----//
  createOrder: `${order}/putOnsale`,
  mylistOrder: `${order}/mylistOrder`,

  // -----*NFT ALL LIST*-----//
  listAllNft: `${order}/listOrder`,

  viewNFT: `${order}/viewOrder`,
  cancelOrder: `${order}/cancelOrderAdmin`,

  buyOrder: `${order}/buyOrder`,
  mylistOrder: `${order}/mylistOrder`,
  topBuyer: `${order}/topBuyer`,
  boughtNft: `${order}/boughtNft`,
  resaleOrder: `${order}/resaleOrder`,
  topSeller: `${order}/topSeller`,
  cancelOrderList: `${order}/cancelOrderList`,
  updateOrder: `${order}/updateOrder`,

  // listAllNft: `${nft}/listNFTWithPagination`,
  createCollection: `${album}/createAlbum`,
  myCollectionList: `${album}/myAlbumList`,
  viewAlbum: `${album}/viewAlbum`,
  albumList: `${album}/albumList`,
  viewAlbum: `${album}/viewAlbum`,
  deleteAlbum: `${album}/deleteAlbum`,

  uploadImage: `${nft}/uploadImage`,

  // -----*Creater*-----//
  listUser: `${user}/listUser`,
  followUnfollowUser: `${user}/followUnfollowUser`,
  subscribeToNewsLetter: `${user}/subscribeToNewsLetter`,
  activityList: `${user}/activityList`,

  // -----*Contact Us*-----//
  contactUs: `${user}/contactUs`,

  // -----*offer*------//
  createOffer: `${offer}/createOffer`,
  sellOffer: `${offer}/sellOffer`,
  acceptOffer: `${offer}/acceptBid`,

  cancelOffer: `${offer}/cancelOffer`,
  listOffer: `${offer}/listOffer`,
  viewOffer: `${offer}/viewOffer`,
  createBid: `${offer}/createBid`,
  bidList: `${offer}/bidList`,
  myBidList: `${offer}/myBidList`,
  cancelBid: `${offer}/cancelBid`,
  // -------static content ------//
  staticContentList: `${staticContent}/staticContentList`,
  listFAQ: `${staticContent}/listFAQ`,
  editStaticData: `${staticContent}/staticContent`,

  // ----------------Admin-------------
  userList: `${adminData}/listUser`,
  viewUser: `${adminData}/viewUser`,
  faqList: `${staticContent}/listFAQ`,
  addFAQ: `${staticContent}/addFAQ`,
  viewFAQ: `${staticContent}/viewFAQ`,
  deleteFAQ: `${staticContent}/deleteFAQ`,
  editFAQ: `${staticContent}/editFAQ`,
  transactionList: `${adminData}/transactionList`,
  viewTransaction: `${adminData}/viewTransaction`,
  approveRejectRequest: `${adminData}/approveRejectRequest`,
  activeBlockUser: `${adminData}/activeBlockUser`,

  // ---------category------
  listCategory: `${categoryData}/listCategory`,
  updateCategory: `${categoryData}/updateCategory`,
  deleteCategory: `${categoryData}/deleteCategory`,
  addCategory: `${categoryData}/addCategory`,
  moodListing: `${categoryData}/moodList`,
  createMood: `${categoryData}/createMood`,
  updateMoodData: `${categoryData}/updateMoodData`,
  deleteMood: `${categoryData}/deleteMood`,
};

export default Apiconfigs;
