import {
  Box,
  Container,
  IconButton,
  makeStyles,
  Typography,
  Link,
  Divider,
} from "@material-ui/core";
import React, { useContext } from "react";
import { RiFacebookFill } from "react-icons/ri";
import {
  AiFillYoutube,
  AiOutlineWhatsApp,
  AiOutlineInstagram,
} from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { UserContext } from "src/context/User";

const useStyles = makeStyles((theme) => ({
  footerMainContainer: {
    position: "relative",
    zIndex: "1",
    overflow: "hidden",
    "& .UpImageBox1": {
      "& img": {
        position: "absolute",
        right: "0px",
        bottom: "85px",
        maxWidth: "400px",
        "@media (max-width :722px)": {
          display: "none",
        },
      },
    },

    background: "#070707",
    "& p": {
      color: "rgba(255, 255, 255, 0.6)",
      maxWidth: "300px",
      padding: "20px 0px 0px",
      fontSize: "14px",
      [theme.breakpoints.only("xs")]: {
        maxWidth: "100%",
      },
    },
    "& .MainIconButonBox": {
      display: "flex",
      padding: "10px 0px 15px",
      "& .socialIcon": {
        marginRight: "5px",
        background: "#a81b2d",
        borderRadius: "100px",
        padding: "3px",

        "& .MuiIconButton-root": {
          background: "#a81b2d",
          color: "#000",
          padding: "2px",
          fontSize: "14px",
          border: "3px solid #000",
          "&:hover": {
            border: "3px solid #a81b2d",
          },
        },
      },
    },
    "& .footerLogoBox": {
      "& img": {
        width: "100%",
        maxWidth: "180px",
      },
    },
    "& .footerBottomSection": {
      flexWrap: "wrap",
      margin: "15px 0",
      [theme.breakpoints.only("xs")]: {
        display: "block",
      },
      "& p": {
        color: "rgba(255, 255, 255, 0.6)",
        padding: "10px",
        whiteSpace: "pre",
        cursor: "pointer",
        [theme.breakpoints.only("xs")]: {},
      },
    },
  },
  footerdesciption: {
    "& p": {
      padding: "0px 0px 10px",
      fontSize: "12px",
    },
  },

  UpImageBox: {
    "& img": {
      position: "absolute",
      right: "0px",
      bottom: "0px",
      maxWidth: "600px",
      zIndex: "-1",
      "@media (min-width :722px)": {
        display: "none",
      },
    },
  },
}));
export default function Footer() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  return (
    <Box className={classes.footerMainContainer}>
      <Box className="UpImageBox1">
        <img src="images/footer.png" alt="" />
      </Box>
      <Box className={classes.UpImageBox}>
        <img src="images/core.png" alt="" />
      </Box>

      <Container maxWidth="fixed">
        <Box mt={5} className="footerLogoBox">
          <img src="images/logo.png" alt="image" />
        </Box>
        <Box>
          <Typography variant="body1" className="ubuntu">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </Typography>
        </Box>
        <Box className="MainIconButonBox">
          <Box className="socialIcon">
            <Link target="_blank" href="https://www.facebook.com/">
              <IconButton>
                <RiFacebookFill />
              </IconButton>
            </Link>
          </Box>
          <Box className="socialIcon">
            <Link target="_blank" href="https://www.instagram.com/">
              <IconButton>
                <AiOutlineInstagram />
              </IconButton>
            </Link>
          </Box>
          <Box className="socialIcon">
            <Link target="_blank" href="https://www.whatsapp.com/">
              <IconButton>
                <AiOutlineWhatsApp />
              </IconButton>
            </Link>
          </Box>
          <Box className="socialIcon">
            <Link target="_blank" href="https://www.youtube.com/">
              <IconButton>
                <AiFillYoutube />
              </IconButton>
            </Link>
          </Box>
        </Box>
        <Box className={classes.footerdesciption}>
          <Typography
            variant="body1"
            className="ubuntu"
            style={{ maxWidth: "100%" }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit,{" "}
          </Typography>
        </Box>
      </Container>
      <Box mt={2}>
        <Divider style={{ background: "rgba(255, 255, 255, 0.1)" }} />
      </Box>
      <Container maxWidth="fixed">
        <Box className="footerBottomSection displaySpacebetween">
          <Box display="flex" flexWrap="wrap">
            <Box onClick={() => history.push("about-us")}>
              <Typography variant="body1" className="sora">
                About Us
              </Typography>
            </Box>
            <Box onClick={() => history.push("privacy-policy")}>
              <Typography variant="body1" className="sora">
                Privacy Policy
              </Typography>
            </Box>
            <Box onClick={() => history.push("terms-conditions")}>
              <Typography variant="body1" className="sora">
                Terms & Conditions
              </Typography>
            </Box>
            {/* <Box
              onClick={() => {
                if (user?.userData?.userType === "CREATOR") {
                  history.push({
                    pathname: "/artist-profile",
                    search: user?.userData?._id,
                  });
                } else {
                  history.push({
                    pathname: "/author-profile",
                    search: user?.userData?._id,
                  });
                }
              }}
              ml={1}
            >
              <Typography variant="body1" className="sora">
                Profile
              </Typography>
            </Box>*/}
            <Box onClick={() => history.push("/marketplace")}>
              <Typography variant="body1" className="sora">
                MarketPlace
              </Typography>
            </Box>
            <Box onClick={() => history.push("/Experience")}>
              <Typography variant="body1" className="sora">
                Experience
              </Typography>
            </Box>
            {/*{user?.userData?.userType !== "ADMIN" && (
              <Box onClick={() => history.push("create-nft")} ml={2}>
                <Typography variant="body1" className="sora">
                  Create NFT
                </Typography>
              </Box>
            )}*/}

            <Box onClick={() => history.push("lets-connect")}>
              <Typography variant="body1" className="sora">
                Partnerships
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography
              variant="body1"
              className="sora"
              style={{ maxWidth: "100%", width: "auto" }}
            >
              Copyright©2023. Created with love by{" "}
              <span style={{ color: "#A81B2D" }}> Beat Nami </span>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
