import React from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Grid,
  Hidden,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  connectModalBox: {
    padding: "30px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
    "& .imageBox": {
      position: "absolute",
      bottom: "30px",
      left: "114px",
      maxWidth: "360px",
    },
    "& .eagleBirdBox": {
      position: "absolute",
      top: "25px",
      left: "-25px",
    },
    "& h4": {
      color: "#fff",
      fontWeight: "300",
    },
    "& h6": {
      color: "rgba(255, 255, 255, 0.6)",
      fontWeight: "300",
      marginTop: "12px",
    },
    "& p": {
      color: "red",
    },
    "& .gridFlex": {
      padding: "30px 27px",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
  },
  walletBox: {
    display: "flex",

    "& .Wallet": {
      background: "rgb(21 20 20)",
      padding: "16px 24px",
      borderRadius: "14px",
      // minWidth: "113px",
      marginRight: "9px",
      marginTop: "24px",
      border: "1px solid #2c2c2c",
      cursor: "pointer",
      "& h5": {
        color: "#fff",
        whiteSpace: "pre",
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px !important",
          marginTop: "6px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        padding: "20px",
        marginLeft: "5px",
      },
      "& img": {
        [theme.breakpoints.down("xs")]: {
          maxWidth: "35px",
          width: "auto",
        },
      },
    },
  },
}));
export default function DisconnectModal({ user, open, onClose }) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogContent className="borderShadowBox">
        <Box className={classes.connectModalBox}>
          <Grid container spacing={5}>
            <Hidden smDown>
              <Grid item xs={6} sm={6} md={6}>
                <Box className="circleBoxSmall"></Box>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                <Box className="gridFlex">
                  <Box>
                    <Typography className="ubuntu" variant="h4">
                      Disconnect Wallet
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="ubuntu" variant="h6">
                      Are you sure want to disconnect your wallet.
                    </Typography>
                  </Box>
                  <Box className={classes.walletBox}>
                    <Box align="center" className="Wallet" onClick={onClose}>
                      <Typography className="ubuntu" variant="h5">
                        No
                      </Typography>
                    </Box>
                    <Box
                      align="center"
                      className="Wallet"
                      onClick={() => user.disconnectWallet()}
                    >
                      <Typography className="ubuntu" variant="h5">
                        Yes
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Box className="imageBox">
              <img width="100%" src="images/home.png" />
            </Box>
            <Box className="eagleBirdBox">
              <img src="images/eagleBird.png" />
            </Box>
          </Hidden>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
