import { Contract } from "@ethersproject/contracts";
import { RPC_URL } from "src/constants";
import Web3 from "web3";
export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

export function sortName(str1) {
  var split_names = str1.trim().split(" ");
  if (split_names.length > 1) {
    return split_names[0] + " " + split_names[1].charAt(0) + ".";
  }
  return split_names[0];
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}
export const getWeb3Provider = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  return httpProvider;
};

export const getWeb3Obj = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = await new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = async (abi, contractAddress) => {
  const web3 = await getWeb3Obj();
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export function isValidFacebookURL(value) {
  const re =
    /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/;
  return re.test(value);
}
export function isValidInstaURL(value) {
  const re =
    /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)/;
  return re.test(value);
}
export function isValidURL(value) {
  const re =
    /((http|https):\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return re.test(value);
}
export function spotifyURL(value) {
  const re =
    /^https?:\/\/(?:open|play)\.spotify\.com\/(track|album|artist|playlist)\/[a-zA-Z0-9]+$/;
  return re.test(value);
}
export function soundCloudURL(value) {
  const re =
    /^https?:\/\/(?:www\.)?soundcloud\.com\/[a-zA-Z0-9-_]+\/[a-zA-Z0-9-_]+$/;
  return re.test(value);
}
export function isValidTiktokURL(value) {
  const re =
    /(?:http(?:s)?:\/\/)?(?:(?:www)\.(?:tiktok\.com)(?:\/)(?!foryou)(@[a-zA-z0-9]+)(?:\/)(?:video)(?:\/)([\d]+)|(?:m)\.(?:tiktok\.com)(?:\/)(?!foryou)(?:v)(?:\/)?(?=([\d]+)\.html))/gm;
  return re.test(value);
}
export function isValidLinkedInURL(value) {
  const re = /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/;
  return re.test(value);
}
export function generateUniqueAvatar(value) {
  return `https://avatars.dicebear.com/api/identicon/${value}.svg`;
}

export async function validateAccountAddress(account) {
  try {
    const web3 = await getWeb3Obj();
    const accountCheck = web3.utils.toChecksumAddress(account);
    if (accountCheck !== "") {
      return true;
    }
  } catch (error) {
    return false;
  }
}

export function testName(value) {
  const regex = /^[A-Za-z]+(\s*[A-Za-z]+)*$/;
  return regex.test(value);
}

export const getCreatorRole = [
  {
    title: "Instrumentalist",
  },
  {
    title: "Producer",
  },
  {
    title: "Songwriter",
  },
  {
    title: "Featured Artist",
  },
  {
    title: "Sound Engineer",
  },
  {
    title: "Mix Engineer",
  },
  {
    title: "Vocal Engineer",
  },
  {
    title: "Lyricist",
  },
];
export function changeExtenstion(str) {
  const checkFormat1 = str && str.includes(".png");
  const checkFormat2 = str && str.includes(".jpg");
  const checkFormat3 = str && str.includes(".jpeg");
  const checkFormat4 = str && str.includes(".webp");

  if (checkFormat1) {
    return str.replace(".png", ".webp");
  }
  if (checkFormat2) {
    return str.replace(".jpg", ".webp");
  }
  if (checkFormat3) {
    return str.replace(".jpeg", ".webp");
  }
  if (checkFormat4) {
    return str;
  }
}
