import React, { createContext, useEffect, useState } from "react";
import { ACTIVE_NETWORK, NetworkDetails } from "src/constants";
import { useWeb3React } from "@web3-react/core";
import { SUPPORTED_WALLETS } from "src/connectors";
import { getWeb3Obj } from "src/utils";

import {
  getAPIHandle,
  getPerticularData,
  postAPIHandle,
} from "src/ApiConfig/service";
import { Redirect, useHistory } from "react-router-dom";
export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate } = useWeb3React();
  const [isOpenConnectWallet, setIsOpenConnectWallet] = useState(false);
  const [categoryList, setCategoryList] = useState("");
  const [userData, setUserData] = useState({});
  const [albumList, setAlbumList] = useState([]);
  const [moodList, setMoodList] = useState([]);
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  //-----*WEB3-Connection-Establisher*-----//
  const connectWalletHandler = (data) => {
    try {
      const connector = data?.connector;
      localStorage.setItem("walletName", data?.name);
      sessionStorage.removeItem("walletName");
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }
      activate(connector, undefined, true).catch((error) => {
        if (error) {
          localStorage.removeItem("walletName");
          // activate(connector);
          console.log("error", error);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.error(JSON.stringify(error.message));
    }
  };

  //-----*WEB3-Network-Change-Request*-----//
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  //-----*WEB3-Network-Add-Request*-----//
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.warn(error.message);
    }
  };

  //-----*WEB3-Connection-Disable*-----//
  const disconnectWalletHandler = async () => {
    try {
      deactivate();
      // toast.success("You have been disconnected successfully!");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userType");
      window.localStorage.removeItem("walletName");
      window.location = "/";
    } catch (error) {
      console.log(error);
    }
  };

  //-----*APIs-START*-----//
  const connectWalletAPIHandler = async (userAddress) => {
    try {
      const response = await postAPIHandle("connectWallet", {
        walletAddress: userAddress,
      });

      if (response) {
        window.localStorage.setItem("token", response.token);
        getProfileAPIHandler(response.token);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProfileAPIHandler = async (token) => {
    try {
      setIsLoading(true);
      getGenreListAPIHandler();
      getMoodListAPIHandler();
      const response = await getAPIHandle("getProfile", {
        headers: {
          token,
        },
      });
      if (response) {
        setUserData(response);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getGenreListAPIHandler = async () => {
    try {
      const response = await postAPIHandle("categoryList");
      setCategoryList(response.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getMoodListAPIHandler = async () => {
    try {
      const response = await postAPIHandle("moodList");
      setMoodList(response.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getMyCollectionListAPI = async () => {
    try {
      const response = await getPerticularData("myCollectionList");
      if (response) {
        setAlbumList(response.docs);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getMyCollectionListAPI();
  }, []);
  //-----*APIs-END*-----//

  // account balance -----//

  const getUserbalce = async () => {
    const web3 = await getWeb3Obj();
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };
  useEffect(() => {
    if (account) {
      getUserbalce();
    }
  }, [account]);

  //-----*APPLICATION-DATA-LAYER-OBJECT*-----//
  let data = {
    userData,
    categoryList,
    isOpenConnectWallet,
    albumList,
    yourWalletBalance,
    isLoading,
    moodList,
    updateUser: (account) => {
      setSession(account);
    },
    getMyCollectionListAPI: () => getMyCollectionListAPI(),
    connectWallet: (item) => connectWalletHandler(item),
    updateProfileHandler: () =>
      getProfileAPIHandler(window.localStorage.getItem("token")),
    disconnectWallet: () => {
      disconnectWalletHandler();
      // setIsLoading(false);
    },
    setIsOpenConnectWallet: (item) => setIsOpenConnectWallet(item),
  };

  useEffect(() => {
    data.updateUser(account);
    if (account) {
      setIsOpenConnectWallet(false);
      connectWalletAPIHandler(account);
    }
  }, [account]); //eslint-disable-line
  useEffect(() => {
    if (account && chainId) {
      if (chainId != ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]); //eslint-disable-line
  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter((data) => {
        return data?.data?.name == localStorage.getItem("walletName");
      });
      if (selectectWalletDetails.length > 0) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    }
  }, [localStorage.getItem("walletName")]); //eslint-disable-line
  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
