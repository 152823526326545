import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  Box,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import ConnectModal from "src/component/ConnectModal";
import Scroll from "react-scroll";
import { UserContext } from "src/context/User";
import { useWeb3React } from "@web3-react/core";
import { sortAddress } from "src/utils";
import DisconnectModal from "src/component/DiconnectModal";
import { toast } from "react-hot-toast";
const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  joinButton: {
    [theme.breakpoints.down("md")]: {
      // marginLeft: "-13px",
    },
  },

  launchButton: {
    [theme.breakpoints.down("xs")]: {
      padding: "10px 30px !important",
    },
  },
  menuButton: {
    fontSize: "13px",
    // lineHeight: "32px",
    fontWeight: "300",
    borderRadius: 0,
    minWidth: "auto",
    color: "#ffffff",
    padding: "7px 0px !important",
    letterSpacing: "1px",
    marginLeft: "15px",
    fontFamily: "'Nico Moji'",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "7px 0px !important",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&:active": {
      color: "#A81B2D",
    },
    "&[tabIndex]:focus": {
      color: "#A81B2D",
    },
    "&:hover": {
      color: "#A81B2D",
    },
  },

  toolbar: {
    display: "flex",
    padding: "20px 0",
    justifyContent: "space-between",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
    },
  },

  logoDrawer: {
    paddingLeft: "10px",
    width: "200px",
    marginBottom: "15px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#02020F",
    width: "260px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "-2px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    minHeight: "60px !important",
  },
  menuLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  inerrMenu: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    whiteSpace: "nowrap",
    paddingLeft: "25px",
    alignItems: "center",
    // justifyContent: "flex-end",
    justifyContent: "flex-start",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      padding: "0",
    },
    "& li": {
      "&.active": {
        background: "red",
      },
    },
  },

  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    margin: "0",
    height: "50px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      // "& .logoDesk": {
      //   display: "none",
      // },
    },

    "& .buttonRound": {
      width: "41px",
      height: "41px",
      borderRadius: "50%",
      color: "#fff",
      marginLeft: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      border: "2px solid #FFFFFF",
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
      "&:hover": {
        background: "#fff",
        color: "#000",
        // border: "2px solid #000",
      },
    },
  },

  topBox: {},
}));

export default function Header(props) {
  const user = useContext(UserContext);

  const { account } = useWeb3React();
  const [openDisconnect, setOpenDisConnect] = useState(false);
  const {
    toolbar,
    menuul,
    drawerContainer,
    drawericon,
    menuLeft,
    logoDrawer,
    containerHeight,
    inerrMenu,
    mainHeader,
    menuButton,
    launchButton,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const displayDesktop = () => {
    return (
      <Container className="p-0" maxWidth="fixed">
        <Toolbar className={toolbar}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {/* <Grid item xs={2}>
              {productLogo}
            </Grid> */}
            {/* <Grid item xs={7} align="center">{getMenuButtons()}</Grid> */}
            <Grid item xs={12} align="center">
              {" "}
              {menuText}{" "}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            {menuText}
            <div style={{ padding: "16px" }}>{connectBtn}</div>
          </div>
          {user?.userData?.userType === "ADMIN" && (
            <li>
              <ScrollLink
                className={menuButton}
                smooth={true}
                duration={500}
                to="home"
                tabIndex="1"
                onClick={() => history.push("/transaction-management")}
              >
                Dashboard{" "}
              </ScrollLink>{" "}
            </li>
          )}
        </Drawer>

        <div>{productLogo}</div>

        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#fff", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const productLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const connectBtn = <Box></Box>;

  const menuText = (
    <nav>
      <ul class={menuul}>
        <Box className={menuLeft}>
          <div className="logoDesk">
            <ScrollLink
              className={menuButton}
              smooth={true}
              duration={500}
              to="section1"
            >
              {" "}
              {productLogo}
            </ScrollLink>
          </div>
        </Box>
        <li>
          <li className="activecolor">
            <ul className={inerrMenu}>
              {user?.userData?.userType === "ADMIN" && (
                <li>
                  <ScrollLink
                    className={menuButton}
                    smooth={true}
                    duration={500}
                    to="home"
                    tabIndex="1"
                    onClick={() => history.push("/transaction-management")}
                  >
                    Dashboard{" "}
                  </ScrollLink>{" "}
                </li>
              )}

              {/* <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="home"
                  tabIndex="1"
                  onClick={() => history.push("/about")}
                >
                  {" "}
                  About{" "}
                </ScrollLink>{" "}
              </li> */}
              <li>
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="features"
                  tabIndex="2"
                  onClick={() => history.push("nft-type")}
                >
                  Musicians
                </ScrollLink>
              </li>
              {account && (
                <li>
                  <ScrollLink
                    className={menuButton}
                    smooth={true}
                    duration={500}
                    to="home"
                    tabIndex="1"
                    onClick={() => {
                      history.push("/marketplace");
                    }}
                  >
                    MarketPlace
                  </ScrollLink>
                </li>
              )}

              <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="community"
                  tabIndex="4"
                  onClick={() => history.push("/creater-list")}
                >
                  {" "}
                  Creator{" "}
                </ScrollLink>{" "}
              </li>

              {account && (
                <>
                  {user?.userData?.userType !== "ADMIN" && (
                    <li>
                      <ScrollLink
                        className={menuButton}
                        smooth={true}
                        duration={500}
                        to="home"
                        tabIndex="1"
                        onClick={() => {
                          if (user?.userData?.approveStatus === "PENDING") {
                            toast.error("Your KYC is not approved yet.");
                          }
                          if (user?.userData?.approveStatus === "REJECTED") {
                            toast.error("Your KYC is rejected by admin");
                          }
                          if (user?.userData?.approveStatus === "NOT_APPLIED") {
                            toast.error("You are not a creator.");
                            history.push("/connect-creator");
                          } else {
                            history.push("/create-nft");
                          }
                        }}
                      >
                        Create NFT
                      </ScrollLink>
                    </li>
                  )}

                  <li>
                    <ScrollLink
                      className={menuButton}
                      smooth={true}
                      duration={500}
                      to="home"
                      tabIndex="1"
                      onClick={() => {
                        if (user?.userData?.userType === "CREATOR") {
                          history.push({
                            pathname: "/artist-profile",
                            search: user?.userData?._id,
                          });
                        } else {
                          history.push({
                            pathname: "/author-profile",
                            search: user?.userData?._id,
                          });
                        }
                      }}
                    >
                      Profile
                    </ScrollLink>
                  </li>
                </>
              )}

              <li style={{ marginLeft: "15px" }}>
                {!account && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={launchButton}
                    onClick={() => user.setIsOpenConnectWallet(true)}
                  >
                    Connect Wallet
                  </Button>
                )}
                {account && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={launchButton}
                    onClick={() => setOpenDisConnect(true)}
                  >
                    {`Disconnect ${sortAddress(account)}`}
                  </Button>
                )}
              </li>
            </ul>
          </li>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      <AppBar
        style={
          colorChange
            ? {
                backgroundColor: "transparent",
                border: "none",
                backdropFilter: "blur(44px)",
              }
            : { backgroundColor: "#000", border: "none" }
        }
        // style={{background:"#000"}}
        position="fixed"
        className="headerNav"
        elevation={0}
      >
        <Container maxWidth="fixed" className={containerHeight}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      <ConnectModal
        user={user}
        account={account}
        setOpen={() => user.setIsOpenConnectWallet(false)}
        open={user.isOpenConnectWallet}
      />
      <DisconnectModal
        user={user}
        open={openDisconnect}
        onClose={() => setOpenDisConnect(false)}
      />
    </>
  );
}
