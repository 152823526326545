export const ACTIVE_API_URL = process.env.REACT_APP_API_TESTNET;

//---#ACTIVE-CHAIN-INFO#---//
export const NetworkContextName = "Polygon";
export const ACTIVE_NETWORK = 80001;
export const RPC_URL = "https://endpoints.omniatech.io/v1/matic/mumbai/public";

export const NFTMintingMumbai = "0xDB162578a9Aca138bd0496e43d08c257A9F4bC2a"; //testnet
export const NFTMultiMumbai = "0x723BA1C0D329f8eA7CdDDECa914DB48d5Be9538c"; //testnet
export const NFTAuctionMumbai = "0x2b931d4a6690D8620b4c75Bf47c6D217a80F61C6"; //testnet new
export const NFTListingMumbai = "0x045EF223E5346baAD5814f78Ac254acB58a18c33"; //testnet

export const zeroAddress = "0x0000000000000000000000000000000000000000";

//---#ACTIVE-NETWORK-DETAILS#---//
export const NetworkDetails = [
  {
    chainId: "0x13881",
    chainName: "Mumbai",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  },
];

//---#APPLICATION-EXPLICIT-DATA#---//
export const CategoryButtons = [
  {
    name: "Hot & Top Sellers",
  },
  {
    name: "Gaming Items",
  },
  {
    name: "Metaverse",
  },
  {
    name: "Art",
  },
  {
    name: "Videos",
  },
  {
    name: "Letter of wishes",
  },
  {
    name: "Web 3.0",
  },
];
export const RankingButtons = [
  {
    name: "Last 10 Days",
  },
  {
    name: "Last 30 Days",
  },
  {
    name: "Last 10 Days",
  },
  {
    name: "Last 20 Days",
  },
  {
    name: "Last 7 Days",
  },
  {
    name: "Last 15 Days",
  },
  {
    name: "Last 30 Days",
  },
];

export const collectionData = [
  {
    image: "images/Collections/Collection1.png",
    img: "images/Sellers/Sellers1.png",
    name: "Sophia Valentine",
    price: "0.99  ETH",
  },
  {
    image: "images/Collections/Collection2.png",
    img: "images/Sellers/Sellers2.png",
    name: "The Metakey",
    price: "0.43  ETH",
  },
  {
    image: "images/Collections/Collection3.png",
    img: "images/Sellers/Sellers3.png",
    name: "Diamond HODLR",
    price: "4.73  ETH",
  },
  {
    image: "images/Collections/Collection4.png",
    img: "images/Sellers/Sellers4.png",
    name: "Sophia Valentine",
    price: "5.22  ETH",
  },
  {
    image: "images/Collections/Collection5.png",
    img: "images/Sellers/Sellers1.png",
    name: "Sophia Valentine",
    price: "0.99  ETH",
  },
  {
    image: "images/Collections/Collection1.png",
    img: "images/Sellers/Sellers2.png",
    name: "The Metakey",
    price: "0.43  ETH",
  },
  {
    image: "images/Collections/Collection2.png",
    img: "images/Sellers/Sellers3.png",
    name: "Diamond HODLR",
    price: "4.73  ETH",
  },
  {
    image: "images/Collections/Collection3.png",
    img: "images/Sellers/Sellers4.png",
    name: "Sophia Valentine",
    price: "5.22  ETH",
  },
];

export const exploreData = [
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore1.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore2.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore3.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore4.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore1.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore2.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore3.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
  {
    user: "@Alex",
    likes: "152",
    time: "8h : 15m : 25s left",
    stock: "4 in stock",
    text3: "From 1.35 ETH 11/Bid 1.1 w",
    image: "images/Explore/Explore4.png",
    name: "Skyblue Creator",
    price: "0.004 ETH",
  },
];

export const tableData = [
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text6: "3.2 K",
    text5: "10.0K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
  {
    image: "images/Profile.png",
    name: "Abcd Efght",
    text1: " 133,871.43",
    text2: "-48.31%",
    text3: "+1396.90%",
    text4: "133",
    text5: "10.0K",
    text6: "3.2 K",
  },
];
