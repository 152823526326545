import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiPagination: {
      ul: {
        margin: "0",
        display: "flex",
        padding: "0",
        listStyle: "none",
        alignItems: "center",
        flexWrap: "inherit",
      },
    },
    PrivateValueLabel: {
      circle: {
        backgroundColor: "#e71833",
        overflow: "hidden",
        height: "40px !important",
        width: "40px !important",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px",
      },
    },
    MuiMenu: {
      list: {
        outline: "0",
        background: "black",
        color: "#ffffffb0",
        fontSize: "13px",
        fontWeight: "300",
        fontFamily: "'Sora' !important",
      },
    },
    MuiCheckbox: {
      root: {
        color: "rgba(255, 255, 255, 0.1)",
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#000000",
        borderRadius: "20px",
      },
    },
    MuiDialogContent: {
      root: {
        flex: "1 1 auto",
        padding: "5px",
        paddingTop: "5px !important",
        borderRadius: "20px",
      },
    },
    MuiDropzoneArea: {
      root: {
        borderRadius: "20px !important",
        borderColor: "#2C2C2C !important",
        backgroundColor: "transparent !important",
        minHeight: "200px !important",
        "&:hover": {
          color: "#ffffff !important",
          borderColor: "#2C2C2C !important",
          backgroundOrigin: "border-box",
          backgroundColor: "rgba(255, 255, 255, 0.05) !important",
        },
      },
    },
    MuiButton: {
      label: {
        fontFamily: "Ubuntu",
      },
      root: {
        textTransform: "capitalize",
      },
      containedPrimary: {
        color: "#02020F",
        backgroundColor: "#E7DDCE",
        border: "2px solid #02020F",
        whiteSpace: "pre",
        "&:hover": {
          color: "#ffffff !important",
          border: " 2px solid rgb(255 255 255 / 1%) !important",
          backgroundOrigin: "border-box",
          backgroundColor: "rgba(255, 255, 255, 0.05) !important",
        },
      },
      containedSecondary: {
        backgroundColor: "#a81b2d",
        border: "1px solid #02020F",
        whiteSpace: "pre",
        color: "#ffffff !important",
        "&:hover": {
          color: "#a81b2d !important",
          border: "1px solid #02020F",
          backgroundOrigin: "border-box",
          backgroundColor: "#ffffff !important",
        },
      },
      contained: {
        fontFamily: "'Ubuntu'",
        borderRadius: "10px",
        color: "#02020F",
        fontWeight: 600,
        padding: "7px 31px",
        // "& .Mui-disabled": {
        //   color: "red !important",
        //   boxShadow: " none",
        //   backgroundColor: "#b49241 !important",
        // },
      },
      outlined: {
        fontFamily: "'Ubuntu'",
        borderRadius: "10px",
        color: "#ffffff !important",
        backgroundColor: "#000",
        border: "1px solid #E7DDCE",
        fontWeight: 600,
        padding: "6px 25px",
      },
      outlinedSizeSmall: {
        padding: "8px 40px",
      },
      outlinedPrimary: {
        background: "linear-gradient(136.96deg, #FF1735 7.24%, #A81B2D 87.99%)",
        color: "#fff",
        fontWeight: "400",
        padding: "13px 30px",
      },

      containedSizeLarge: {
        width: "100%",
      },

      containedSizeMedium: {
        minWidth: "216px !important",
        width: "auto",
      },
    },
    MuiCollapse: {
      wrapperInner: {
        background: "transparent",
        paddingTop: "15px",
      },
    },

    typography: {
      root: {
        color: "#ffffff",
      },
    },

    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: "8px",
      },
      input: {
        padding: "2px 10px",
      },
      root: {
        background: "#2C2C2C",
        borderRadius: "10px",
      },
      notchedOutline: {
        borderColor: "#000",
      },
    },
    MuiInputBase: {
      fullWidth: {
        position: "relative",
        background: "rgba(255, 255, 255, 0.1)",
        borderRadius: "10px",
      },
      input: {
        height: "50px",
        fontSize: " 14px",
        padding: "0px",
        color: "rgba(255, 255, 255, 0.6)",
        fontWeight: "300",
      },
    },
    MuiFilledInput: {
      root: {
        position: "relative",
        transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        backgroundColor: "#2c2c2c",
        borderRadius: "10px !important",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      input: {
        backgroundColor: "#2C2C2C",
        borderRadius: "10px",
        padding: "0px",
        color: "#E7DDCE",

        "&:-webkit-autofill": {
          borderRadius: "10px !important",
          WebkitBackgroundClip: "text !important",
          WebkitTextFillColor: "#fff !important",
          WebkitBoxShadow: "0 0 0 1000px #2C2C2C inset",
          marginRight: "10px",
          textOverflow: "ellipsis !important",
        },
      },

      inputMultiline: {
        padding: "14px 10px",
      },

      multiline: {
        padding: "0px",
      },
      underline: {
        "&:hover": {
          "&::before": {
            borderBottom: "none",
          },
        },
        "&::before": {
          borderBottom: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },

    MuiTableCell: {
      root: {
        fontWeight: "400",
        fontFamily: "Arimo",
      },
      head: {
        padding: "15px 5px",
        whiteSpace: "pre",
        color: "#E7DDCE",
      },
      body: {
        color: "#E7DDCE",
        borderBottom: "none",
        whiteSpace: "pre",
        padding: "4px",
      },
    },
    MuiAccordionDetails: {
      root: {
        background: "#1D1D1D",
      },
    },
    MuiAccordionSummary: {
      root: {
        background: "rgb(83 80 74)",
        fontFamily: "Nico Moji",
        padding: "6px 16px",
        background: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(30px)",
        borderRadius: "10px",
        "& .Mui-expanded": {
          minHeight: "0px !important",
        },
      },
    },
    MuiPaginationItem: {
      root: {
        color: "#ffffff",
      },
    },

    MuiAccordion: {
      root: {
        color: "#ffffff",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#A81B2D",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
