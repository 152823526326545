import { UserContext } from "src/context/User";
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "src/context/Auth";

export default function AuthGuard(props) {
  const { children } = props;
  const auth = useContext(AuthContext);
  const user = useContext(UserContext);

  if (
    user?.userData?.userType === "USER" ||
    user?.userData?.userType === "CREATOR"
  ) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
}
