/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { FaUserAlt } from "react-icons/fa";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import {
  AiOutlineDashboard,
  GiReceiveMoney,
  IoIosContact,
} from "react-icons/all";
import NavItem from "./NavItem";
import { MdDescription } from "react-icons/md";
const sections = [
  {
    items: [
      {
        title: "Transaction Management",
        icon: GiReceiveMoney,
        href: "/transaction-management",
      },
      {
        title: "Category Management",
        icon: GiReceiveMoney,
        href: "/category-management",
      },
      {
        title: "Mood Management",
        icon: GiReceiveMoney,
        href: "/mood-management",
      },
      {
        title: "User Management",
        icon: FaUserAlt,
        href: "/user-management",
      },

      {
        title: "Control",
        icon: IoIosContact,
        href: "/admin-control",
      },
      // {
      //   title: "FAQ Management",
      //   icon: MdDescription,
      //   href: "/static-content",
      // },
      {
        title: "Static Management",
        icon: MdDescription,
        href: "/static-management",
      },
      {
        title: "FAQ Management",
        icon: MdDescription,
        href: "/faq-list",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    backgroundColor: "#141414",
  },
  desktopDrawer: {
    width: 250,
    top: 76,
    height: "100%",
    background: "rgba(255, 255, 255, 0.08)",
    borderRight: "0",
    boxShadow: "4px 3px 4px rgba(0, 0, 0, 0.1)",
    padding: "0px 8px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        {/* <Box className={classes.btnBox}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Logout
          </Button>
        </Box> */}
      </PerfectScrollbar>
      <ConfirmationDialog
        open={open}
        handleClose={() => setOpen(false)}
        title={"Logout"}
        desc={"Do you want to Logout ?"}
      />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
